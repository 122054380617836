<template>
  <div class="newpassword">
    <NewPassword/>
  </div>
</template>

<script>
import NewPassword from "../components/NewPassword.vue";
 export default {
    components: { NewPassword }
}
</script>
<style lang="scss" scoped>

</style>
